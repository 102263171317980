export default [

  {
    key: 'employee_name',
    label: 'employee name',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '20%' },
    tdStyle: { width: '20%' },
  },

  {
    key: 'document_status',
    label: 'documents status',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '30%' },
    tdStyle: { width: '30%' },
  },

  {
    key: 'department',
    label: 'department',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '20%' },
    tdStyle: { width: '20%' },
  },

  {
    key: 'entry_date',
    label: 'entry date',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
    sortable: true,
  },

  {
    key: 'deadline_date',
    label: 'deadline date',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
    sortable: true,
  },

  {
    key: 'actions',
    label: 'actions',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
  },
];
