/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ProfileService {
  async initializeEssentialDocumments(body) {
    try {
      const { data, status } = await amgApi.post('/profile/initialize-essential-documments', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getEssentialDocuments(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-essential-documments-rrhh', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async approveOrRejectDocument(body) {
    try {
      const { data, status } = await amgApi.post('/profile/approve-or-reject-documment', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getEssentialDocumentsDeadline() {
    try {
      const { data, status } = await amgApi.post('/profile/get-essential-documents-deadline');
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateEssentialDocumentsDeadline(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-essential-documents-deadline', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getEssentialDocumentsType(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-essential-documents-type', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateEssentialDocumentsType(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-essential-documents-type', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}

export default new ProfileService();
