<template>
  <div>
    <header-slot />
    <b-card>
      <b-button
        class="ml-2 mt-2"
        variant="primary"
        @click="modalDeadlineShow = true"
      >
        Change Deadline
      </b-button>
      <b-button
        class="ml-2 mt-2"
        variant="info"
        @click="essentialDocummentsTypeModal = true"
      >
        Change Essential Documments
      </b-button>
      <div class="mt-2 mx-auto d-block text-center">
        <h3 class="mb-1">
          Legend
        </h3>
        <div
          class="d-flex justify-content-center align-items-center p-1"
          :class="isDarkSkin ? 'border-top-white border-bottom-white':'list-group-item-light'"
        >
          <strong
            class="text-primary border-right-secondary px-2"
          >
            <feather-icon
              icon="FileIcon"
            />
            Pending to upload
          </strong>
          <strong
            class="text-warning border-right-secondary px-2"
          >
            <feather-icon
              icon="FileIcon"
            />
            Pending to approve
          </strong>
          <strong
            class="text-success border-right-secondary px-2"
          >
            <feather-icon
              icon="FileIcon"
            />
            Approved
          </strong>
          <strong
            class="text-danger px-1"
          >
            <feather-icon
              icon="FileIcon"
            />
            Rejected
          </strong>
        </div>
      </div>
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refTable'].refresh()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refTable"
            no-provider-filtering
            :items="search"
            :fields="visibleFields"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #cell(document_status)="data">
              <template v-for="item in data.item.documents">
                <a
                  v-if="item.url"
                  :key="item.id"
                  :href="item.url"
                  target="_blank"
                >
                  <feather-icon
                    v-b-tooltip.hover.bottom
                    icon="FileIcon"
                    size="18"
                    style="margin: 8px;"
                    class="hover-me"
                    :class="{
                      'text-primary': item.status === 'pending to upload',
                      'text-warning': item.status === 'pending to approve',
                      'text-success': item.status === 'approved',
                      'text-danger': item.status === 'rejected'
                    }"
                    :title="item.name"
                  />
                </a>
                <feather-icon
                  v-else
                  :key="item.id"
                  v-b-tooltip.hover.bottom
                  icon="FileIcon"
                  size="18"
                  style="margin: 8px;"
                  :class="{
                    'text-primary': item.status === 'pending to upload',
                    'text-warning': item.status === 'pending to approve',
                    'text-success': item.status === 'approved',
                    'text-danger': item.status === 'rejected'
                  }"
                  :title="item.name"
                />
              </template>
            </template>
            <template #cell(actions)="data">
              <!-- <b-button
                variant="outline-info"
                class="sm"
                size="sm"
                @click="openModal(data.item.documents,data.item.employee_name)"
              > -->
              <feather-icon
                v-b-tooltip.hover.v-info
                v-b-tooltip.hover.bottom
                title="View documents"
                icon="EyeIcon"
                size="20"
                class="hover-me text-info cursor-pointer"
                @click="openModal(data.item.documents,data.item.employee_name)"
              />
              <!-- </b-button> -->
            </template>
            <template #cell(department)="data">
              {{ data.item.documents[0].module_name }}
            </template>
            <template #cell(entry_date)="data">
              {{ data.item.entry_date | myGlobal }}
            </template>
            <template #cell(deadline_date)="data">
              {{ data.item.deadline_date | myGlobal }}
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>

    <b-modal
      id="modal-essential-documments"
      v-model="modalEssentialDocumemntsShow"
      size="xl"
      :title="'Upload Essential Documments of ' + selectedEmployee"
      :hide-footer="true"
    ><div class="mt-3 mx-auto d-block text-center">
       <b-button variant="outline-primary">
         Pending to upload : {{ pendingUploadDocumentsCounter }}
       </b-button>
       <b-button
         class="ml-1"
         variant="outline-warning"
       >
         Pending to approve : {{ pendingApproveDocumentsCounter }}
       </b-button>
       <b-button
         class="ml-1"
         variant="outline-success"
       >
         Approved : {{ approvedDocumentsCounter }}
       </b-button>
       <b-button
         class="ml-1"
         variant="outline-danger"
       >
         Rejected : {{ rejectedDocumentsCounter }}
       </b-button>
     </div>
      <p class="my-4">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Documment</th>
              <th>View document</th>
              <th>Status</th>
              <th>Updated at</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="employeeDocumment in employeeDocumments"
              :key="employeeDocumment.id"
            >
              <td>
                {{ employeeDocumment.name }}
              </td>
              <td>
                <b-button
                  v-if="employeeDocumment.url"
                  variant="outline-primary"
                  @click="contentClicked(employeeDocumment.url)"
                ><feather-icon
                  icon="EyeIcon"
                />
                </b-button>
                <span v-else>
                  Not available
                </span>
              </td>
              <td>
                <strong
                  :class="{
                    'text-primary': employeeDocumment.status === 'pending to upload',
                    'text-warning': employeeDocumment.status === 'pending to approve',
                    'text-success': employeeDocumment.status === 'approved',
                    'text-danger': employeeDocumment.status === 'rejected'
                  }"
                >
                  {{ toTitleCase(employeeDocumment.status) }}
                </strong>
              </td>
              <td>
                <feather-icon
                  icon="CalendarIcon"
                  size="20"
                />
                {{ employeeDocumment.updated_at | myGlobalDay }}
              </td>
              <td>
                <b-button
                  v-if="employeeDocumment.status === 'pending to approve'"
                  variant="outline-success"
                  @click="approveOrRejectDocument(employeeDocumment,1)"
                >
                  <strong>
                    Approve
                  </strong>
                </b-button>
                <b-button
                  v-if="employeeDocumment.status === 'pending to approve'"
                  class="ml-1"
                  variant="outline-danger"
                  @click="approveOrRejectDocument(employeeDocumment,2)"
                >
                  <strong>
                    Reject
                  </strong>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </p>
    </b-modal>

    <b-modal
      v-model="modalDeadlineShow"
      size="sm"
      title="Deadline Config"
      centered
    >
      <div class="row">
        <div class="my-1 col-md-12 text-center">
          <span>
            Total number of days to upload documents from the day the employee account is created.
          </span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="mb-2 col-md-4">
          <b-form-select
            v-model="deadlineQuantity"
            :options="daysOfMonth"
          />
        </div>
      </div>

      <template #modal-footer="">
        <b-button
          size="sm"
          variant="primary"
          @click="updateEssentialDocumentsDeadline()"
        >
          SAVE CONFIG
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="essentialDocummentsTypeModal"
      size="sm"
      title="Essential Documments Config"
      centered
    >
      <div class="row justify-content-center">
        <validation-observer ref="observer">
          <table class="table">
            <thead>
              <tr>
                <th>NAME</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(doc, index) in essentialDocummentsType"
                :key="doc.id"
              >
                <td>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="doc.name"
                      size="sm"
                      :state="errors[0] ? false : null"
                      placeholder="Enter essential doc name"
                    /><small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider></td>
                <td>
                  <feather-icon
                    class="text-danger cursor-pointer"
                    icon="Trash2Icon"
                    size="20"
                    @click="deleteDoc(index,doc)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            size="sm"
            variant="outline-success"
            @click="addNewDocument"
          >
            Add Document
          </b-button>
        </validation-observer>
      </div>
      <template #modal-footer="">
        <b-button
          size="sm"
          variant="primary"
          @click="updateEssentialDocumentsType()"
        >
          SAVE CONFIG
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Fields from './fields.essentials-docs.data';
import filtersData from './filters.essentials-docs.data';
import Service from './essential-documents.service';

export default {
  data() {
    return {
      selectedEmployee: null,
      modalDeadlineShow: false,
      modalEssentialDocumemntsShow: false,
      employeeDocumments: [],
      pendingUploadDocumentsCounter: 0,
      pendingApproveDocumentsCounter: 0,
      approvedDocumentsCounter: 0,
      rejectedDocumentsCounter: 0,
      file: null,
      fileName: null,
      form: {
        file: '',
        image: '',
        remove: false,
      },
      daysOfMonth: [],
      deadlineQuantity: 1,
      essentialDocummentsType: [],
      essentialDocummentsTypeDeleted: [],
      essentialDocummentsTypeModal: false,

      // GRILLA
      operation: 1,
      modal: false,
      holidaySelected: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'entry_date',
      sortDesc: true,
      fields: Fields,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Type Employee name or Department name...',
        model: '',
      },
      filter: filtersData,
      transactionId: null,
      newForm: {
        description: '',
        day: null,
        month: null,
        created_by: '',
        rpt: '1',
        type: 'Day Calendar',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    visibleFields() {
      return this.fields;
    },
  },
  mounted() {
    this.initializeDaysOfMonth();
    this.getEssentialDocumentsDeadline();
    this.getEssentialDocumentsType();
  },
  methods: {

    initializeDaysOfMonth() {
      for (let i = 1; i <= 30; i += 1) {
        this.daysOfMonth.push({
          value: i,
          text: `${i} day${i !== 1 ? 's' : ''}`,
        });
      }
    },

    async getEssentialDocumentsDeadline() {
      try {
        this.addPreloader();
        const res = await Service.getEssentialDocumentsDeadline();
        if (res.status === 200) {
          this.deadlineQuantity = res.data.quantity;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async getEssentialDocumentsType() {
      try {
        this.addPreloader();
        const res = await Service.getEssentialDocumentsType();
        if (res.status === 200) {
          this.essentialDocummentsType = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    deleteDoc(index, doc) {
      this.essentialDocummentsTypeDeleted.push(doc);
      this.essentialDocummentsType.splice(index, 1);
    },

    addNewDocument() {
      const newDocument = {
        id: null,
        name: null,
      };
      this.essentialDocummentsType.push(newDocument);
    },

    async updateEssentialDocumentsType() {
      try {
        const validation = await this.$refs.observer.validate();
        if (!validation) return;
        const resultState = await this.showConfirmSwal();
        if (!resultState.isConfirmed) return;
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
          essentialDocummentsType: this.essentialDocummentsType,
          essentialDocummentsTypeDeleted: this.essentialDocummentsTypeDeleted,
        };
        const res = await Service.updateEssentialDocumentsType(obj);
        if (res.status === 200) {
          this.essentialDocummentsTypeModal = false;
          this.getEssentialDocumentsType();
          this.removePreloader();
          this.showSuccessSwal();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },

    async updateEssentialDocumentsDeadline() {
      try {
        this.addPreloader();
        const obj = {
          quantity: this.deadlineQuantity,
        };
        const res = await Service.updateEssentialDocumentsDeadline(obj);
        if (res.status === 200) {
          this.deadlineQuantity = res.data.quantity;
          this.modalDeadlineShow = false;
          this.removePreloader();
          this.showSuccessSwal();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },

    refreshTable() {
      this.$refs.refTable.refresh();
    },

    async search(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
        };

        const res = await Service.getEssentialDocuments(obj);
        if (res.status === 200) {
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;
          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },

    async initializeEssentialDocumments() {
      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
        };
        const res = await Service.initializeEssentialDocumments(obj);
        if (res.status === 200) {
          this.employeeDocumments = res.data;
          await this.initializeCounter();
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async initializeCounter() {
      this.pendingUploadDocumentsCounter = 0;
      this.pendingApproveDocumentsCounter = 0;
      this.approvedDocumentsCounter = 0;
      this.rejectedDocumentsCounter = 0;
      if (this.employeeDocumments) {
        this.employeeDocumments.forEach(document => {
          if (document.status === 'pending to upload') this.pendingUploadDocumentsCounter += 1;
          if (document.status === 'pending to approve') this.pendingApproveDocumentsCounter += 1;
          if (document.status === 'approved') this.approvedDocumentsCounter += 1;
          if (document.status === 'rejected') this.rejectedDocumentsCounter += 1;
        });
      }
    },

    async contentClicked(url) {
      window.open(
        url,
        '_blank',
      );
    },

    toTitleCase(inputString) {
      return inputString.toLowerCase().replace(/^(.)|\s(.)/g, match => match.toUpperCase());
    },

    openModal(documents, employee) {
      this.employeeDocumments = documents;
      this.selectedEmployee = employee;
      this.initializeCounter();
      this.modalEssentialDocumemntsShow = true;
    },

    async approveOrRejectDocument(document, option) {
      const resultState = await this.showConfirmSwal();

      if (!resultState.isConfirmed) return;

      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
          idFileModule: document.id,
          option,
        };
        const res = await Service.approveOrRejectDocument(obj);
        if (res.status === 200) {
          document.status = res.data;
          await this.initializeCounter();
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
  },
};
</script>
